.wrapper {
    width: 100%;
}

.container {
    max-width: 1440px;
    margin: auto;
    padding: 80px 40px;
    display: flex;
    align-items: center;
    grid-gap: 50px;
    justify-content: space-between;
}

.img {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    border: 24px solid rgba(255, 255, 255, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
}

.img>img {
    width: calc(400px - 48px);
    height: calc(400px- 48px);
}

.token_form {
    max-width: 700px;
    padding: 32px 40px;
    border-radius: 24px;
    background: rgba(233, 242, 255, 0.10);
    backdrop-filter: blur(60px);
}

.heading {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
}

.heading>h3 {
    color: #FFF;
    font-size: 28px;
    font-weight: 600;
    text-transform: capitalize;
}

.heading>p {
    color: #E9F2FF;
    font-size: 16px;
    opacity: 0.45;
    font-weight: 400;
}

.input_list {
    margin-top: 33px;
    display: flex;
    grid-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.input_list>div {
    width: 100%;
}

.input_list>div:nth-child(3) {
    width: 48%;
}

.input_list>div:nth-child(4) {
    width: 48%;
}

.input {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    padding: 12px 16px;
    border-radius: 16px;
    background: rgba(233, 242, 255, 0.10);
}

.form_detail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
}

.form_detail>h5 {
    color: rgba(255, 255, 255, 0.70);
    font-size: 14px;
    font-weight: 500;
}

.form_detail>h6 {
    color: rgba(233, 242, 255, 0.50);
    font-size: 12px;
    font-weight: 400;
}

.vertical_line {
    display: block;
    width: 1px;
    height: 28px;
    border-radius: 0.4rem;
    background: rgba(233, 242, 255, 0.10);
}

.input input {
    outline: none;
    width: 160px;
    background: transparent;
    border: none;
    color: rgba(233, 242, 255, 0.50);
    font-size: 14px;
    font-weight: 400;
}

.input input::placeholder {
    color: rgba(233, 242, 255, 0.50);
    font-size: 14px;
    font-weight: 400;
}

.fee {
    padding: 16px;
    color: rgba(255, 255, 255, 0.70);
    font-size: 14px;
    font-weight: 400;
}

.token_form>button {
    width: 100%;
    border-radius: 16px;
    padding: 16px 24px;
    background: #1A71F2;
    box-shadow: 0px 12px 120px 0px rgba(44, 70, 240, 0.30);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
}

@media (max-width: 1199.98px) {
    .input_list>div:nth-child(3) {
        width: 100%;
    }

    .input_list>div:nth-child(4) {
        width: 100%;
    }
}

/* for Medium devices --------------
---------------------*/
@media (max-width: 1060px) {}

/* For tablets */
@media (max-width: 991.98px) {
    .container {
        padding: 60px 20px;
        grid-gap: 25px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .token_form {
        min-width: 420px;
        padding: 30px 20px;
    }

    .img {
        width: 350px;
        height: 350px;
    }

    .img>img {
        width: 310px;
        height: 310px;
    }
}

/* Small devices (landscape phone, )------------------
--------------------------- */

@media (max-width: 767.98px) {
    .container {
        padding: 30px 20px;
    }
}

/* Small devices such as phone -------------------
-----------------------------------*/

@media (max-width: 575.98px) {}

/* Xtra small devices -------------------
-----------------------------------*/

@media (max-width: 480.98px) {
    .img {
        width: 330px;
        height: 330px;
    }

    .img>img {
        width: 300px;
        height: 300px;
    }

    .token_form {
        min-width: 220px;
        padding: 25px 10px;
    }
}