.wrapper {
    width: 100%;
    margin-top: 40px;
}

.container {
    max-width: 1440px;
    padding: 40px;
    margin: auto;
}

.content {
    background: linear-gradient(50deg, #1A71F2 0%, #791AF2 100%), url(../../images/launchPad_background.png), -1892939048562209000px -14754729077322365000px / 985152586612554600% 7268339446956830000% no-repeat;
    border-radius: 24px;
    background-blend-mode: multiply, normal;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 30px;
}

.content_left {
    width: fit-content;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    position: relative;
}

.content_left>h4 {
    color: #E9F2FF;
    font-family: MonumentExtended;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0.032rem;
    text-transform: capitalize;
}

.content_left>p {
    max-width: 700px;
    color: #E9F2FF;
    font-size: 16px;
    opacity: 0.46;
    font-weight: 300;
}

.content_left>img {
    position: absolute;
    right: -120px;
    top: -10px;
}

.button {
    background: #fff;
    display: flex;
    padding: 10px 10px 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 18px;
    border-radius: 16px;
    box-shadow: 0px 18px 24px -14px rgba(13, 26, 44, 0.20);
    cursor: pointer;
}

.button>p {
    background: linear-gradient(103deg, #1A71F2 0%, #791AF2 100%), url(../../images/rectangle-12531@2x.png), -153.771px -45.537px / 220.875% 165.656% no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-blend-mode: color, normal;
    background-clip: text;
    filter: blur(0px);
    color: #F8FAFF;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
}

.arrow_right {
    width: 40px;
    height: 32px;
    border-radius: 10px;
    background: #1C064B;
    box-shadow: 0px 7px 14px 0px rgba(81, 31, 186, 0.34);
    background: linear-gradient(103deg, #1A71F2 0%, #791AF2 100%), url(../../images/launchPad_btn_background.png), -40.667px -12.043px / 220.875% 165.656% no-repeat;
    background-blend-mode: color, normal;
    filter: blur(0px);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1199.98px) {}

/* for Medium devices --------------
---------------------*/
@media (max-width: 1060px) {}

/* For tablets */
@media (max-width: 991.98px) {
    .container {
        padding: 40px 20px;
    }
}

/* Small devices (landscape phone, )------------------
--------------------------- */

@media (max-width: 767.98px) {
    .content {
        flex-direction: column;
        padding: 30px 10px;
    }

    .content_left {
        text-align: center;
    }

    .content_left > img {
        bottom: -20px;
        top: auto;
        right: -30px;
        transform: rotate(130deg);
    }
}

/* Small devices such as phone -------------------
-----------------------------------*/

@media (max-width: 575.98px) {}

/* Xtra small devices -------------------
-----------------------------------*/

@media (max-width: 480.98px) {}