.learnMore {
  width: 100%;
  border-radius: 1.5rem;
  border: 1px solid var(--primary-primary-300, #4c62f2);
  background: linear-gradient(131deg, #1A71F2 0%, #791AF2 100%), url(../../images/feature_card_background.png), -398.952px -272.001px / 208.141% 258.268% no-repeat, #1A71F2;
  background-size: cover;
  background-blend-mode: color, luminosity, normal;
  background-repeat: no-repeat;
  box-shadow: 0px 24px 120px 0px rgba(44, 70, 240, 0.15);
  height: 7rem;
  flex-shrink: 0;
  padding: 1.5rem 1.2rem 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 768px) {
  /* .learnMore {
    max-width: 24.6875rem;
  } */
}

.arrow {
  position: absolute;
  top: 4rem;
  left: 8rem;
}

.textSecondary {
  color: #e9f2ff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 1.5rem */
  opacity: 0.6;
}

.textPrimary {
  color: #e9f2ff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 165%;
  /* 2.475rem */
  text-transform: capitalize;
}

.specialButton {
  display: inline-flex;
  padding: 0.625rem 0.625rem 0.625rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 1.125rem;
  border-radius: 1rem;
  background: #e9f2ff;
  box-shadow: 0px 18px 24px -10px rgba(13, 26, 44, 0.3);
  outline: none;
  border: none;
  color: purple;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.5rem;
  /* 171.429% */
  text-transform: uppercase;
}

.specialButton span {
  background: linear-gradient(135deg, #1a71f2 0%, #791af2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.arrowBox {
  background: linear-gradient(103deg, #1A71F2 0%, #791AF2 100%), url(../../images/launchPad_btn_background.png), -40.667px -12.043px / 220.875% 165.656% no-repeat;
  background-blend-mode: color, normal;
  filter: blur(0px);
  width: 2.75rem;
  height: 2rem;
  flex-shrink: 0;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}