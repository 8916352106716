.app {
  width: 100%;
  background: url(./images/vector1.png), url(./images/vector2.png) ;
  background-repeat: no-repeat;
  background-position: top -25rem left -25rem, right -46rem top 15rem ;
  /* background-size: 1233px , 1458px; */
  overflow-x: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page2 {
  max-width: 1440px;
  margin: 0 auto;
  padding: 1.5rem 40px;
  display: flex;
  gap: 1.5rem;
  width: fit-content;
}

.left-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* For tablets */
@media (max-width: 991.98px) {
  .page2 {
    flex-direction: column;
    padding: 1.5rem 20px;
  }
}


@media screen and (max-width: 768px) {}