:root {
    --error-error-400: #E23737;
    --success-success-400: #2E7D32
}

.wrapper {
    width: 100%;
    padding-bottom: 80px;
}

.projectsCards {
    max-width: 1440px;
    margin: auto;
    display: flex;
    padding: 0 40px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 20px;
}

.cardsContainer {
    width: 394px;
    border-radius: 24px;
    padding-bottom: 20px;
    background: rgba(233, 242, 255, 0.10);
    box-shadow: 0px 16px 160px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(60px);
    overflow: hidden;
}

.groupAndStatus {
    display: flex;
}

.groupImage {
    margin-inline-start: 32px;
    margin-block-start: -60px;
}

.cardStatus {
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
    margin-inline: auto 32px;
    margin-block-start: auto;
}

.cardStatusError {
    background: var(--error-error-400);
}

.cardStatusSuccess {
    background: var(--success-success-400);
}

.status {
    color: #FFF;
    font-family: Inter;
    font-size: .875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 171.429%;
    /* 24px */
}

.cardHeading {
    color: #E9F2FF;
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 165%;
    margin: 30px;
}

.dayAndTimeContainer {
    display: flex;
    width: 330px;
    height: 42px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    margin: 30px;
}

.dayAndTimeChip {
    display: flex;
    padding: 16px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 16px;
    border: none;
    background: rgba(233, 242, 255, 0.06);
}

.dayAndTimeChipText {
    color: rgba(233, 242, 255, 0.80);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}

.cardInfo {
    display: flex;
    width: 330px;
    padding: 1px 0px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 18px;
    background: rgba(233, 242, 255, 0.06);
    margin: auto;
}

.cardInfoParameters {
    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid rgba(233, 242, 255, 0.08);
}

.cardInfoParametersNoBorder {
    border-bottom: none
}

.cardInfoParametersKey {
    flex: 1 0 0;
    color: rgba(233, 242, 255, 0.40);
    font-family: Inter;
    font-size: .875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    /* 23.8px */
}

.cardInfoParametersValue {
    color: rgba(233, 242, 255, 0.50);
    text-align: right;
    flex: auto;
}

.progressBarText {
    color: #E9F2FF;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 24px */
    opacity: 0.8;
}

.progressBarConatainer {
    margin: 10px 30px;
}

.progressBarPercentageText {
    font-size: .875rem;
}

.progressBar {
    width: 100%;
    margin-block: 11px;
    padding: 3px;
    overflow: hidden;
    border-radius: 74px;
    background: rgba(233, 242, 255, 0.06);
    display: flex;
    position: relative;
    align-items: center;
    height: 16px;
}

.progressBar>img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-blend-mode: color, normal;
    filter: blur(0px);
    border-radius: 70px;
}

.USDT {
    display: flex;
    justify-content: space-between;
}

.USDT span {
    color: #E9F2FF;
    font-family: Inter;
    font-size: .75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 16.2px */
    opacity: .5;
}

.button {
    display: flex;
    width: 330px;
    padding: 14px 10px 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 18px;
    border-radius: 16px;
    border: none;
    margin-inline: 32px;
    color: rgba(233, 242, 255, 0.80);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 24px */
    text-transform: capitalize;
}

.activeButton {
    background: #1A71F2;
}

.inActiveButton {
    background: rgba(233, 242, 255, 0.13);
}

@media (max-width: 480.98px) {
    .projectsCards {
        padding: 0 20px;
    }

    .cardsContainer {
        width: 394px;
        border-radius: 24px;
        background: rgba(233, 242, 255, 0.10);
        box-shadow: 0px 16px 160px 0px rgba(0, 0, 0, 0.10);
        backdrop-filter: blur(60px);
        overflow: hidden;
        /* margin: auto; */
    }

    .cardHeading {
        margin: 15px;
    }

    .dayAndTimeChip {
        flex: none;
        padding: 16px;
    }

    .dayAndTimeContainer {
        margin: 15px;
    }

    .cardInfo {
        width: calc(100% - 30px);
    }

    .button {
        display: flex;
        width: calc(100% - 30px);
        padding: 14px 10px 14px 24px;
        margin-inline: 0;
        margin: auto;
    }
}