.wrapper {
    width: 100%;
}

.features {
    max-width: 1440px;
    padding: 40px;
    margin: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
}

.featureHeading {
    color: #D9D9D9;
    text-align: center;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    background-image: url(../../assets/Rectangle_1255.png);
    background-repeat: no-repeat;
    background-clip: text;
    background-position: center;
    -webkit-background-clip: text;
    color: transparent;
    background-blend-mode: color, normal;
}

.featureHeadingInfo {
    color: rgba(233, 242, 255, 0.90);
    text-align: center;
    font-family: MonumentExtended;
    font-size: 2.5rem;
    font-weight: 400;
    text-transform: capitalize;
}

.featurePara {
    max-width: 700px;
    margin: auto;
    color: #E9F2FF;
    text-align: center;
    font-size: 16px;
    opacity: 0.26;
    font-weight: 400;
}

.featuresCardsContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
}

.featuresCards {
    flex-grow: 1;
    border-radius: 24px;
    padding: 24px;
    background: rgba(233, 242, 255, 0.06);
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    width: 280px;
}

.featuresCards.active {
    background: linear-gradient(131deg, #1A71F2 0%, #791AF2 100%), url(../../images/feature_card_background.png), -398.952px -272.001px / 208.141% 258.268% no-repeat, #1A71F2;
    background-size: cover;
    background-blend-mode: color, luminosity, normal;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
}

.featuresCards>h6 {
    color: #E9F2FF;
    opacity: 0.8;
    font-size: 20px;
    font-weight: 700;
}

.featuresCards>p {
    opacity: 0.3;
    color: #E9F2FF;
    font-size: 14px;
    font-weight: 400;
}

.featuresCardsBgDark:hover {
    background: linear-gradient(131deg, #1A71F2 0%, #791AF2 100%);
}

.iconImageContainer {
    display: flex;
    width: 56px;
    height: 56px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 18px;
    background: #E9F2FF;
    box-shadow: 0px 4px 8px 0px rgba(15, 24, 35, 0.10);
}

@media (max-width: 1199.98px) {}

/* for Medium devices --------------
---------------------*/
@media (max-width: 1060px) {
    .featuresCardsContainer {
        grid-gap: 20px;
    }
}

/* For tablets */
@media (max-width: 991.98px) {
    .features {
        padding: 40px 20px;
    }
}

/* Small devices (landscape phone, )------------------
--------------------------- */

@media (max-width: 767.98px) {
   
}

/* Small devices such as phone -------------------
-----------------------------------*/

@media (max-width: 575.98px) {}

/* Xtra small devices -------------------
-----------------------------------*/

@media (max-width: 480.98px) {}