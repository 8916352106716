.card {
  max-width: 395px;
  height: auto;
  padding: 1rem;
  border-radius: 1.5rem;
  background: #e9f2ff1a;
  padding: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* For tablets */
@media (max-width: 991.98px) {
  .card {
    max-width: 100%;
  }
}

.card .heading {
  color: #e9f2ff;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 2rem */
  padding: 0 1rem;
}

.card .subcard {
  background-color: #e9f2ff0d;
  padding: 1.25rem 1.5rem;
  border-radius: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.card .textPrimary {
  color: #e9f2ff;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 175%; /* 1.96875rem */
}

.card .textSecondary {
  color: #e9f2ff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.card .labels {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: #e9f2ff;
  padding: 0 2rem;
  gap: 0.5rem;
}

.card .labelColor {
  width: 0.75rem;
  height: 0.375rem;
  border-radius: 1rem;
}

.card .labelWithDot {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.chart {
  width: 100%;
  height: 280px;
  position: relative;
  overflow: hidden;
}

.responsive {
  padding-bottom: 0.5rem;
}
