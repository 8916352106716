.wrapper {
    width: 100%;
    background: url(../images/landing_background.png), -48px 0px / 106.667% 101.014% no-repeat, #06101F;
    background-repeat: no-repeat;
    background-blend-mode: overlay, normal;
    background-size: 100%;
}

.container {
    width: 100%;
    background: url(../images/vector1.png), url(../images/vector2.png) ;
    background-repeat: no-repeat;
    background-position: top -25rem left -25rem, right -46rem top 15rem ;
    /* background-size: 1233px , 1458px; */
    overflow-x: hidden;  
}

@media (max-width: 1060px) {
    .wrapper {
        background-size: auto;
    }
}
