.card {
  width: 100%;
  /* max-width: 395px; */
  height: auto;
  padding: 1.5rem 0;
  border-radius: 1.5rem;
  background: #e9f2ff1a;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.heading {
  font-size: 20px;
  font-weight: 600;
  color: #e9f2ff;
  line-height: 1;
  padding: 0 2rem;
}

.listItems {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.listItem {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #e9f2ff12;
  border-radius: 1.125rem;
  padding: 0.5rem;
}

.lastListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e9f2ff12;
  border-radius: 1.125rem;
  padding: 0.5rem 1rem;
  min-height: 3.25rem;
}

.lastListItem img {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 999px;
}

.iconOuter {
  padding: 0.5rem;
  border-radius: 0.75rem;
  background-color: #e9f2ff1a;
  width: fit-content;
  height: auto;
  display: flex;
  justify-content: center;
}

.itemSection {
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
  color: #e9f2ff;
}

.itemSection .secondary {
  opacity: 0.5;
}

.icon {
  height: 24px;
  width: 24px;
}

.justifyBetween {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;
}

.textSecondary {
  color: #e9f2ff;
  opacity: 0.6;
  font-size: 1rem;
}

.textPrimary {
  color: #e9f2ff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bottom .content {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  font-size: 14px;
  color: #e9f2ffb2;
}

.divider {
  border-color: #e9f2ff12;
  margin: 1rem 0;
}
