.wrapper {
    width: 100%;
    position: relative;
}

.container {
    width: 100%;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all 0.3s ease;
}

.container.active {
    background: #0C1521;    
}

.container>div {
    padding: 20px 40px;
    max-width: 1440px;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    grid-gap: 45px;
}

.nav_left {
    display: flex;
    align-items: center;
    width: fit-content;
}

.hamburger {
    display: none;
}

.nav_links {
    display: flex;
    align-items: center;
    grid-gap: 40px;
}

.nav_links>a {
    color: rgba(233, 242, 255, 0.80);
    font-size: 14px;
    font-weight: 400;
}

.nav_right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttons {
    grid-gap: 25px;
    display: flex;
    align-items: center;
}

.nav_right button {
    height: 52px;
    cursor: pointer;
}

.close {
    display: none;
}

.btn_ethereum {
    display: flex;
    align-items: center;
    grid-gap: 18px;
    padding: 10px 24px 10px 10px;
    color: #E9F2FF;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.8;
    border-radius: 16px;
    background: rgba(233, 242, 255, 0.14);
}

.btn_ethereum>span {
    display: block;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.06);
}

.btn_wallet {
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    border-radius: 16px;
    background: linear-gradient(90deg, #1A71F2 0%, #791AF2 100%);
    box-shadow: 0px 12px 120px 0px rgba(44, 70, 240, 0.50);
    padding: 14px 34px;
    background: linear-gradient(103deg, #1A71F2 0%, #791AF2 100%), url(../../images/wallet_background.png), -138.521px -41.021px / 220.875% 165.656% no-repeat;
    background-size: cover;
    background-blend-mode: color, normal;
}

@media (max-width: 1199.98px) {}

/* for Medium devices --------------
---------------------*/
@media (max-width: 1060px) {}

/* For tablets */
@media (max-width: 991.98px) {
    .container>div {
        padding: 25px 20px;
    }

    .hamburger {
        display: block;
        color: #fff;
        font-size: 25px;
        cursor: pointer;
    }

    .nav_right {
        width: 230px;
        position: absolute;
        right: -100%;
        top: 0;
        height: 100vh;
        flex-direction: column;
        justify-content: flex-start;
        background: #0C1521;
        padding: 25px 20px;
        grid-gap: 20px;
        border-left: 1px solid rgba(233, 242, 255, 0.10);
        transition: all 0.3s ease;
    }

    .close {
        display: block;
        color: #fff;
        font-size: 25px;
        cursor: pointer;
        align-self: flex-end;
    }

    .nav_right.active {
        right: 0;
    }

    .nav_links {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .buttons {
        width: 100%;
        flex-direction: column;
        grid-gap: 15px;
    }

    .buttons > button {
        width: 100%;
    }
}


/* Small devices (landscape phone, )------------------
--------------------------- */

@media (max-width: 767.98px) {}

/* Small devices such as phone -------------------
-----------------------------------*/

@media (max-width: 575.98px) {
   
}

/* Xtra small devices -------------------
-----------------------------------*/

@media (max-width: 480.98px) {}