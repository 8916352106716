.keyValue {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 0 14px;

  padding: 10px 0;
}
.firstKeyValue {
  padding-top: 0;
}
.keyValue:last-child {
  border: none;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  grid-gap: 16px;

  border-radius: 16px;
  background: rgba(233, 242, 255, 0.1);
}
.label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.2px;

  display: none;
  padding: 0px 16px;
}
.input {
  background: transparent;
  border: none;
  color: rgba(233, 242, 255, 0.5);
  font-size: 14px;
  font-weight: 400;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 17px 16px;
  border-radius: 16px;
  border: none;
  outline: none;
}
.deleteContainer {
  background: transparent;
  border-radius: 10px;
  width: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background: rgba(233, 242, 255, 0.1);
}

.deleteIcon {
  color: rgba(255, 255, 255, 0.7);
  font-size: 22px;
  cursor: pointer;
}
@media only screen and (max-width: 640px) {
  .inputWrapper {
    background: none;
    gap: 10px;
  }

  .keyValue {
    grid-template-columns: 1fr;
    gap: 18px;
    align-items: end;
    padding: 8px 0;
  }
  .firstKeyValue {
    padding-top: 0;
  }
  .label {
    display: block;
  }
  .input {
    background: rgba(233, 242, 255, 0.1);
    padding: 17px 16px;
  }
  .deleteContainer {
    width: 33px;
    height: 33px;

    margin-left: auto;

    margin-bottom: -10px;
  }

  .deleteIcon {
    font-size: 20px;
  }
}
