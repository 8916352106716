.badge {
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.75rem;
  width: fit-content;
  height: fit-content;
}

.icon {
  width: 1.125rem;
  height: 1.125rem;
}

.text {
  color: #e9f2ff;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 171.429% */
}
