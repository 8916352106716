.wrapper {
  width: 100%;
  position: relative;
}

.blob1 {
  position: absolute;
  width: 130px;
  top: 140px;
  left: -40px;
}

.blob2 {
  position: absolute;
  width: 96px;
  right: -35px;
  top: 500px;
}

.container {
  max-width: 1440px;
  margin: auto;
  display: flex;
  padding: 100px 40px;
  flex-direction: column;
  align-items: center;
}

.container>h2 {
  color: rgba(233, 242, 255, 0.90);
  text-align: center;
  font-family: MonumentExtended;
  font-size: 48px;
  font-weight: 400;
  text-transform: capitalize;
  max-width: 1020px;
}

.investorsAi {
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.blockchain {
  /* background: linear-gradient(157deg, #1A71F2 8.09%, #8E5CFF 46.88%, #1236CF 93.18%); */
  background: linear-gradient(144deg, #1A71F2 0%, #791AF2 100%), url(../../images/web3_img.png), -243.147px 68.563px / 207.646% 155.734% no-repeat;
  background-size: cover;
  background-blend-mode: color, normal;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  filter: blur(0px);
}

.container>p {
  margin-top: 16px;
  max-width: 700px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.26;
  color: #E9F2FF;
}

.highlights {
  display: flex;
  align-items: center;
  grid-gap: 100px;
  margin-top: 48px;
}

.titles {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.titles>h5 {
  color: rgba(233, 242, 255, 0.70);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.titles>h6 {
  color: rgba(233, 242, 255, 0.50);
  font-size: 16px;
  font-weight: 400;
}

.buttons {
  display: flex;
  align-items: center;
  grid-gap: 34px;
  margin-top: 48px;
}

.buttons>button {
  height: 52px;
  cursor: pointer;
}

.allProjects {
  padding: 10px 10px 10px 24px;
  border-radius: 16px;
  /* background: linear-gradient(90deg, #1A71F2 0%, #791AF2 100%); */
  box-shadow: 0px 18px 24px -14px rgba(111, 96, 254, 0.32);
  color: #F8FAFF;
  font-size: 14px;
  background: linear-gradient(103deg, #1A71F2 0%, #791AF2 100%), url(../../images/wallet_background.png), -138.521px -41.021px / 220.875% 165.656% no-repeat;
  background-blend-mode: color, normal;
  filter: blur(0px);
  font-weight: 700;
  background-size: cover;
  display: flex;
  align-items: center;
  grid-gap: 18px;
  text-transform: capitalize;
}

.newProject {
  padding: 14px 24px;
  border-radius: 16px;
  background: rgba(233, 242, 255, 0.14);
  opacity: 0.8;
  color: #E9F2FF;
  font-size: 16px;
  font-weight: 500;
}

.allProjects>div {
  width: 40px;
  height: 32px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(28, 6, 75, 0.22);
  box-shadow: 0px 7px 14px 0px rgba(81, 31, 186, 0.34);
}


@media (max-width: 1199.98px) {}

/* for Medium devices --------------
---------------------*/
@media (max-width: 1060px) {}

/* For tablets */
@media (max-width: 991.98px) {
    .container {
        padding: 100px 20px;
    }

    .container > h2 {
      font-size: 32px;
    }

    .blob1 {
      width: 90px;
    }

    .nav_links {
        display: none;
    }
}

/* Small devices (landscape phone, )------------------
--------------------------- */

@media (max-width: 767.98px) {
    .highlights {
      grid-gap: 50px;
    }
}

/* Small devices such as phone -------------------
-----------------------------------*/

@media (max-width: 575.98px) {
    .nav_right {
        display: none;
    }

    .container {
      padding: 60px 20px;
    }

    .highlights {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
}

/* Xtra small devices -------------------
-----------------------------------*/

@media (max-width: 480.98px) {}