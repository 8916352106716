.container {
  width: 100%;
  height: fit-content;
  background-color: #e9f2ff1a;
  border-radius: 1.5rem;
  backdrop-filter: blur(60px);
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 2.5rem 1.5rem 3rem;
  gap: 2rem;
  width: 100%;
  margin-top: -4.25rem;
}

/* For tablets */
@media (max-width: 991.98px) {
  .container {
    max-width: 50.8125rem;
  }

  .header {
    margin-top: -4.25rem;
  }
}


.badges {
  display: flex;
  gap: 0.75rem;
}

.icon {
  display: inline-flex;
  padding: 0.375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: #e9f2ff1a;
}

.textLight {
  color: #e9f2ffb2;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 1.5rem */
}

.spacing {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.heading {
  color: #e9f2ff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
  /* 3.5rem */
  text-transform: capitalize;
}

.avatar {
  border: 3px solid transparent;
  border-radius: 9999px;
  outline: 2px solid #1a71f2;
}

.wraper {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}

.textFaded {
  color: #e9f2ff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 177%;
  opacity: 0.26;
  padding: 0 3rem 1rem 3rem;
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .wraper {
    flex-direction: column;
  }
}

.cardBg {
  width: 100%;
  height: auto;
}

.divider {
  border-color: #e9f2ff1a;
}

.container .textAround {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 25px;
}

.container .textDark {
  color: #e9f2ff99;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  /* 1.96875rem */
}

.textLight {
  color: #e9f2ff;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  /* 1.96875rem */
}

.textSpecial {
  background: linear-gradient(103deg, #1A71F2 0%, #791AF2 100%), url(../../images/Rectangle_1255.png), -111.893px -23.993px / 220.875% 165.656% no-repeat;
  background-blend-mode: color, normal;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 175%;
  text-transform: uppercase;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  filter: blur(0px);
}

@media (max-width: 480.98px) {
  .textFaded, .textAround {
    padding: 0 1rem 1rem 1rem;
  }
}