.wrapper {
  width: 100%;
}

.container {
  max-width: 1440px;
  padding: 100px 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
}

.text {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}

.text>h5 {
  color: #D9D9D9;
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(130deg, #1A71F2 0%, #781af2 40%), url(../../images/web3_img.png), no-repeat;
  ;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-blend-mode: color, normal;
  background-clip: text;
  filter: blur(0px);
}

.text>p {
  color: #E9F2FF;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.26;
}

.text>h2 {
  max-width: 560px;
  color: rgba(233, 242, 255, 0.90);
  font-family: MonumentExtended;
  font-size: 40px;
  font-weight: 400;
  text-transform: capitalize;
}

.web3 {
  color: rgba(233, 242, 255, 0.90);
  text-transform: uppercase;
  font-weight: 600;
  background: linear-gradient(130deg, #1A71F2 0%, #791AF2 100%), url(../../images/web3_img.png), no-repeat;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-blend-mode: color, normal;
  background-clip: text;
  filter: blur(0);
}

.companies {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.logo {
  padding: 30px 24px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(233, 242, 255, 0.06);
}

.logo.active {
  padding: 30px 24px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E9F2FF;
}

.logo>img {
  height: 32px;
  margin: 0 25px;
}

@media (max-width: 1199.98px) {}

/* for Medium devices --------------
---------------------*/
@media (max-width: 1060px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 40px;
  }
}

/* For tablets */
@media (max-width: 991.98px) {
  .container {
    padding: 100px 20px;
  }
}

/* Small devices (landscape phone, )------------------
--------------------------- */

@media (max-width: 767.98px) {
  .companies {
    width: 100%;
    grid-gap: 15px;
  }

  .logo {
    padding: 25px 10px;
  }
}

/* Small devices such as phone -------------------
-----------------------------------*/

@media (max-width: 575.98px) {
  .companies {
    grid-template-columns: 1fr;
  }
}

/* Xtra small devices -------------------
-----------------------------------*/

@media (max-width: 480.98px) {}