.wrapper {
  padding-top: 18px;
}
.titleAndTagline {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 15px;
}

.title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 166.667%;
  letter-spacing: 0.2px;
}
.tagline {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.2px;
}
.form {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding-top: 8px;
}
.labelContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  gap: 0 14px;
  padding-bottom: 12px;
}

.label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.2px;

  padding: 0px 16px;
}
.createNewKey {
  color: #2f39bf;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
  border-radius: 8px;
  padding: 14px 18px;
  background: rgba(205, 208, 241, 0.9);
  border-radius: 16px;

  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
}
.plusIcon {
  font-size: 22px;
}
@media only screen and (max-width: 640px) {
  .labelContainer {
    display: none;
  }
}
@media only screen and (max-width: 520px) {
  .form {
    padding: 15px 10px;
  }
}
