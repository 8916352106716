.statusCard {
  width: 100%;
  height: auto;
  padding: 16px;
  border-radius: 24px;
  background: #e9f2ff1a;
  padding: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.progressDot {
  display: flex;
  align-items: center;
}

.dot {
  margin-bottom: -8px;
}

.statusIndicator {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 68px;
  padding: 20px 24px 20px 24px;
  border-radius: 18px;
  border: 2px solid #2ce1a099;
  gap: 16px;
  background: #2ce19f0f;
  box-shadow: 0px 4px 14px 0px rgba(12, 149, 100, 0.1);
}

.statusCard .statusText {
  color: #e9f2ff;
}

.progress {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.progressBar {
  height: 1rem;
  width: 100%;
  background-color: #e9f2ff0f;
  border-radius: 999px;
}

.progressText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #2ce1a0;
}

.progressTitle {
  color: #e9f2ff;
  font-weight: 600;
}

.statusCard button {
  padding: 14px 10px 14px 24px;
  background: #1a71f2;
  color: #e9f2ff;
  border-radius: 16px;
  outline: none;
  border: none;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.textSecondary {
  color: #e9f2ff;
  font-size: 12px;
}

.buyCoin h3 {
  color: #e9f2ff;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.coinSection {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #e9f2ff1a;
  padding: 1rem;
  border-radius: 1rem;
}

.coinTextSecondary {
  color: #e9f2ff;
  font-size: 14px;
}

.coinTextPrimary {
  color: #e9f2ff;
  font-weight: 700;
}

.input {
  border: none;
  outline: none;
  text-align: right;
  background: transparent;
  padding: 3px 0;
}
.textRight {
  text-align: right;
}
