@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monument+Extended:wght@300;400;700&display=swap");

@font-face {
  font-family: "MonumentExtended";
  src: local("MonumentRegular"),
    url("./fonts/MonumentExtended/MonumentExtended-300.otf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "MonumentExtended";
  src: local("MonumentRegular"),
    url("./fonts/MonumentExtended/MonumentExtended-400.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "MonumentExtended";
  src: local("MonumentRegular"),
    url("./fonts/MonumentExtended/MonumentExtended-500.otf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "MonumentExtended";
  src: local("MonumentBold"),
    url("./fonts/MonumentExtended/MonumentExtended-800.otf") format("truetype");
  font-weight: 800;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-focus-ring-color: transparent !important;
  outline: none !important;
}

body {
  margin: 0;
  line-height: normal;
  background: #0c1521;
  font-family: Inter;
  overflow-x: hidden;
  height: 100%;
}

:root {
  /* fonts */
  --body-body-small: Inter;
  --font-monument-extended: "Monument Extended";

  /* font sizes */
  --body-body-small-size: 14px;
  --heading-h6-size: 20px;
  --caption-caption-04-size: 12px;
  --subtitle-subtitle-01-size: 18px;
  --font-size-smi: 13px;
  --heading-h5-size: 24px;
  --body-body-large-size: 16px;
  --font-size-21xl: 40px;
  --heading-h4-size: 28px;
  --font-size-29xl: 48px;

  /* Colors */
  --color-aliceblue-100: #e9f2ff;
  --color-aliceblue-200: rgba(233, 242, 255, 0.7);
  --color-aliceblue-300: rgba(233, 242, 255, 0.14);
  --color-aliceblue-400: rgba(233, 242, 255, 0.08);
  --color-aliceblue-500: rgba(233, 242, 255, 0.4);
  --color-aliceblue-600: rgba(233, 242, 255, 0.8);
  --color-aliceblue-700: rgba(233, 242, 255, 0.13);
  --color-aliceblue-800: rgba(233, 242, 255, 0.9);
  --color-aliceblue-900: rgba(233, 242, 255, 0.5);
  --color-aliceblue-1000: rgba(233, 242, 255, 0.1);
  --color-aliceblue-1100: rgba(233, 242, 255, 0.06);
  --color-ghostwhite: #f8faff;
  --color-white: #fff;
  --error-error-400: #e23737;
  --success-success-400: #2e7d32;

  /* Gaps */
  --gap-lg: 18px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-3xs: 10px;
  --gap-xs: 12px;
  --gap-base: 16px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-5xl: 24px;
  --padding-9xs: 4px;
  --padding-sm: 14px;
  --padding-base: 16px;
  --padding-11xl: 30px;
  --padding-xl: 20px;
  --padding-12xs: 1px;
  --padding-xs: 12px;
  --padding-lg: 18px;
  --padding-5xs: 8px;

  /* border radiuses */
  --br-5xl: 24px;
  --br-lg: 18px;
  --br-base: 16px;
  --br-5xs: 8px;
  --br-3xl: 22px;
  --br-11xl: 30px;
  --br-55xl: 74px;
  --br-3xs: 10px;
}

a {
  color: #fff;
}

.nav_links > a.active {
  color: #e9f2ff;
  font-weight: 800;
}

button {
  background: transparent;
  outline: none;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

button:hover {
  opacity: 0.7;
}

.progress_red {
  background: linear-gradient(106deg, #fb4e4e 0%, #ea0b4e 100%),
    url(./images/blob2.png), -549.306px 11.773px / 338.84% 254.13% no-repeat;
}

.progress_yellow {
  background: linear-gradient(98deg, #f6d316 0%, #e79e10 100%),
    url(./images/blob2.png), -117.708px 12.456px / 338.84% 254.13% no-repeat;
}

.progress_blue {
  background: linear-gradient(106deg, #1a71f2 0%, #791af2 100%),
    url(./images/blob2.png), -230.512px 11.773px / 338.84% 254.13% no-repeat;
} /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
