.footer_wrapper {
    width: 100%;
    background: rgba(233, 242, 255, 0.05);
}

.footer_top {
    max-width: 1440px;
    margin: auto;
    padding: 56px 40px 47px 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: 40px;
    flex-wrap: wrap;
}

.horizontal_line {
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(233, 242, 255, 0.05);
}

.footer_desc>p {
    color: #E9F2FF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    opacity: 0.5;
    max-width: 290px;
    margin: 20px 0 16px 0;
}

.icons_list {
    display: flex;
    align-items: center;
    grid-gap: 9px;
}

.icons {
    border-radius: 50%;
    width: 46px;
    height: 46px;
    border: 1.4px solid rgba(208, 220, 231, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.icons:hover {
    border: 1.4px solid transparent;
    background: rgba(208, 220, 231, 0.10);
}

.link_flex {
    display: flex;
    grid-gap: 50px;
    justify-content: space-around;
    flex-grow: 1;
}

.links {
    display: flex;
    flex-direction: column;
    grid-gap: 13px;
}

.links>a {
    color: rgba(233, 242, 255, 0.50);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
}

.links>h5 {
    color: #E9F2FF;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.8;
}

.newsLetter {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
}

.newsLetter>h4 {
    color: #E9F2FF;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    opacity: 0.8;
}

.newsLetter>p {
    line-height: 22px;
    max-width: 395px;
    font-family: Inter;
    color: #E9F2FF;
    font-size: 13px;
    font-weight: 400;
    opacity: 0.3;
}

.newsLetter>div {
    display: flex;
    align-items: center;
    grid-gap: 12px;
}

.input {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    padding: 10px 16px;
    border-radius: 16px;
    border: 1.5px solid rgba(233, 242, 255, 0.10);
}

.vertical_line {
    display: block;
    width: 1px;
    height: 28px;
    border-radius: 0.4rem;
    background: rgba(233, 242, 255, 0.10);
}

.input input {
    outline: none;
    background: transparent;
    border: none;
    color: rgba(233, 242, 255, 0.50);
    font-size: 14px;
    font-weight: 400;
}

.input input::placeholder {
    color: rgba(233, 242, 255, 0.50);
    font-size: 14px;
    font-weight: 400;
}

/* ---------------------footer bottom-------------------------- */

.footer_bottom {
    max-width: 1440px;
    margin: auto;
    padding: 28px 40px;
    display: flex;
    justify-content: space-between;
}

.footer_bottom_left {
    display: flex;
    align-items: center;
    grid-gap: 8px;
}

.footer_bottom_left>p {
    color: #E9F2FF;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.7;
}

.round_rectangle {
    display: block;
    width: 8px;
    height: 4px;
    border-radius: 0.8rem;
    background: rgba(233, 242, 255, 0.10);

}

.footer_bottom_right {
    display: flex;
    align-items: center;
    grid-gap: 24px;
}

.footer_bottom_right>p {
    color: #E9F2FF;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
}

.newsletter_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
    cursor: pointer;
    border-radius: 16px;
    height: 52px;
    color: #F8FAFF;
    font-size: 14px;
    background: linear-gradient(103deg, #1A71F2 0%, #781af2 100%), url(../../images/web3_img.png), -138.521px -41.021px / 220.875% 165.656% no-repeat;
    background-blend-mode: color, normal;
    filter: blur(0px);
    font-weight: 600;
}

@media (max-width: 1199.98px) {}

/* for Medium devices --------------
---------------------*/
@media (max-width: 1060px) {}

/* For tablets */
@media (max-width: 991.98px) {
    .footer_top {
        padding: 56px 20px 47px 20px;
    }

    .footer_bottom {
        padding: 28px 20px;
    }
}

/* Small devices (landscape phone, )------------------
--------------------------- */

@media (max-width: 767.98px) {
    .link_flex {
        justify-content: space-between;
    }
}

/* Small devices such as phone -------------------
-----------------------------------*/

@media (max-width: 575.98px) {}

/* Xtra small devices -------------------
-----------------------------------*/

@media (max-width: 480.98px) {
    .link_flex {
        grid-gap: 35px;
    }

    .footer_desc {
        width: 100%;
    }

    .icons_list {
        justify-content: space-between;
    }

    .footer_bottom {
        flex-direction: column-reverse;
        align-items: center;
        grid-gap: 15px;
    }

    .input {
        grid-gap: 10px;
    }

    .input>input {
        width: 160px;
    }
}