.wrapper {
    width: 100%;
}

.projects {
    max-width: 1440px;
    margin: auto;
    display: flex;
    padding: 30px 40px;
    align-items: center;
    justify-content: space-between;
}

.projects>div {
    display: flex;
    align-items: center;
}

.projectsHeading {
    color: #E9F2FF;
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
    opacity: 0.8;
}

.searchBarContainer {
    display: inline-flex;
    align-items: center;
    gap: 32px;
    margin-inline: auto 20px
}

.searchBar {
    display: flex;
    padding: 12px 14px;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: rgba(233, 242, 255, 0.14);
}

.searchBarInput {
    all: unset;
}

.sortBy {
    display: flex;
    width: 194px;
    padding: 8px 8px 8px 20px;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: rgba(233, 242, 255, 0.14);
    position: relative;
}

.sort_wrapper {
    display: none;
    position: absolute;
    top: 55px;
    width: 100%;
    left: 0;
    background: #0C1521;
    border-radius: 12px;
    z-index: 4;
    padding: 5px;
}

.sort_wrapper > li {
    color: rgba(255, 255, 255, 0.70);
    font-size: 16px;
    padding: 10px;
    list-style-type: none;
    background: #0C1521;
    border-radius: 12px;
    cursor: pointer;
}

.sort_wrapper > li:hover {
    background: #121e30d8;
}

.sortBySelect > img {
    cursor: pointer;
}

.sortBy.active > .sortBySelect > img {
    transform: rotate(180deg);
}

.sortBy.active .sort_wrapper {
    display: block;
}

.sortByCaption {
    color: rgba(255, 255, 255, 0.70);
    font-family: Inter;
    font-size: .75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    /* 16.2px */
}

.sortBySelect {
    display: flex;
    padding: 6px 12px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 10px;
    background: rgba(233, 242, 255, 0.10);
}

.sortByOptions {
    color: rgba(233, 242, 255, 0.90);
    font-family: Inter;
    font-size: .813rem;
    font-style: normal;
    font-weight: 600;
    line-height: 156%;
    /* 20.28px */
}

@media (max-width: 1060px) {
    .projects {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 10px;
    }
}

/* For tablets */
@media (max-width: 991.98px) {
    .projects {
        padding: 30px 20px;
    }
}

@media (max-width: 575.98px) {
    .searchBarContainer {
        margin-inline: auto 10px;
    }

    .searchBar {
        padding: 12px 10px;
        gap: 10px;
    }

    .searchBarInput {
        width: 120px;
        font-size: 14px;
        color: #fff;
    }

    .sortBy {
        width: 150px;
        padding: 8px 8px 8px 15px;
    }
}